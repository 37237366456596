import React from 'react'

import PropTypes from 'prop-types'

import './mobile-navigation.css'

const MobileNavigation = (props) => {
  return (
    <div id="mobile-menu" className="mobile-navigation-mobile-navigation">
      <a
        href="https://hinted.me"
        target="_blank"
        rel="noreferrer noopener"
        className="mobile-navigation-link"
      >
        <img
          alt={props.Logo_alt}
          src={props.Logo_src}
          className="mobile-navigation-logo"
        />
      </a>
      <div className="mobile-navigation-links">
        <a href="#about" className="mobile-navigation-text nav-link">
          {props.Text}
        </a>
        <a href="#howitworks" className="mobile-navigation-link1 nav-link">
          {props.text}
        </a>
        <a href="#benefits" className="mobile-navigation-link2 nav-link">
          {props.text1}
        </a>
      </div>
      <div
        id="close-mobile-menu"
        className="mobile-navigation-close-mobile-menu"
      >
        <svg
          viewBox="0 0 804.5714285714286 1024"
          className="mobile-navigation-icon"
        >
          <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
        </svg>
      </div>
    </div>
  )
}

MobileNavigation.defaultProps = {
  Text: 'About',
  Logo_alt: 'pastedImage',
  text: 'How it works',
  Logo_src: '/logos/logo_color_transparent.svg',
  text1: 'Why Us',
}

MobileNavigation.propTypes = {
  Text: PropTypes.string,
  Logo_alt: PropTypes.string,
  text: PropTypes.string,
  Logo_src: PropTypes.string,
  text1: PropTypes.string,
}

export default MobileNavigation
