import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import MobileNavigation from '../components/mobile-navigation'
import Footer from '../components/footer'
import './get-a-demo.css'

const GetADemo = (props) => {
  return (
    <div className="get-a-demo-container">
      <Helmet>
        <title>
          Get-a-Demo - Jivv: Optimize Software Management & Architecture with AI
        </title>
        <meta
          name="description"
          content="Jivv empowers enterprises with smart software management &amp; tailored architecture-as-a-service, ensuring optimal utilization &amp; growth. Dive in with Jivv!"
        />
        <meta
          property="og:title"
          content="Get-a-Demo - Jivv: Optimize Software Management &amp; Architecture with AI"
        />
        <meta
          property="og:description"
          content="Jivv empowers enterprises with smart software management &amp; tailored architecture-as-a-service, ensuring optimal utilization &amp; growth. Dive in with Jivv!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/2dcb23aa-1fcf-410a-99cd-fca1c2aa55d0/4b7c8b80-edd0-461f-9ee8-b6e569103b6f?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <section className="get-a-demo-hero">
        <div className="get-a-demo-menu">
          <MobileNavigation></MobileNavigation>
          <div className="get-a-demo-desktop-navigation">
            <nav className="get-a-demo-centered">
              <div className="get-a-demo-left">
                <a
                  href="https://jivv.ai"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="get-a-demo-link"
                >
                  <img
                    alt="pastedImage"
                    src="/logos/logo_color_transparent.svg"
                    loading="lazy"
                    className="get-a-demo-logo"
                  />
                </a>
              </div>
              <div className="get-a-demo-right">
                <span className="get-a-demo-sign-in Link">Sign in</span>
                <div className="get-a-demo-get-started actions">
                  <span className="get-a-demo-text">Get started</span>
                </div>
                <div id="open-mobile-menu" className="get-a-demo-burger-menu">
                  <img
                    alt="pastedImage"
                    src="/pastedimage-yxbd.svg"
                    className="get-a-demo-mobile-menu-button"
                  />
                </div>
              </div>
            </nav>
          </div>
        </div>
        <header className="get-a-demo-header">
          <h1 className="get-a-demo-text1">
            <span>Optimize your software stack with AI-driven insights</span>
            <br></br>
          </h1>
          <h2 className="get-a-demo-text4">
            Track, analyze, and refine your SaaS and on-prem tools for maximum
            ROI
          </h2>
        </header>
      </section>
      <section className="get-a-demo-features">
        <h1 className="get-a-demo-text5">
          Your software strategy, simplified. Dive deeper!
        </h1>
        <div className="get-a-demo-container1">
          <div className="get-a-demo-container2">
            <Script
              html={`<style>
html {
	 box-sizing: border-box;
	 font-size: 10px;
}
 *, *:before, *:after {
	 box-sizing: inherit;
}
 body, ul, li {
	 margin: 0;
	 padding: 0;
}
 li {
	 list-style: none;
}
 p, h1, h2, h3, h4, h5, h6 {
	 margin-top: 0;
}
 a {
	 text-decoration: none;
}
 input {
	 border-style: none;
	 background: transparent;
	 outline: none;
}
 button {
	 padding: 0;
	 background: none;
	 border: none;
	 outline: none;
}
 body {
	 -webkit-font-smoothing: antialiased;
	 -moz-osx-font-smoothing: grayscale;
}
 h1.demo {
	 text-align: center;
	 font-size: 2.4rem;
	 font-weight: normal;
	 margin-bottom: 1rem;
	 color: #7800ff;
}
 a.demo {
	 text-align: center;
	 font-size: 1.6rem;
	 font-weight: normal;
	 color: rgba(202, 205, 239, 0.8);
	 margin-bottom: 3rem;
}
 .demo-flex-spacer {
	 height: 4rem;
}
 .container {
	 display: flex;
	 flex-direction: column;
	 height: 100vh;
	 max-width: 1600px;
	 padding: 0 15px;
	 margin: 0 auto;
}
 @keyframes gradient {
	 0% {
		 background-position: 0 0;
	}
	 100% {
		 background-position: 100% 0;
	}
}
 .webflow-style-input {
	 position: relative;
	 display: flex;
	 flex-direction: row;
	 width: 100%;
	 max-width: 1500px;
	 margin: 0 auto;
	 border-radius: 2px;
	 padding: 1.4rem 2rem 1.6rem;
	 background: #fff;
}
 .webflow-style-input:after {
	 content: "";
	 position: absolute;
	 left: 0px;
	 right: 0px;
	 bottom: 0px;
	 z-index: 999;
	 height: 5px;
	 border-bottom-left-radius: 2px;
	 border-bottom-right-radius: 2px;
	 background-position: 0% 0%;
	 background: linear-gradient(to right, #b294ff, #57e6e6, #feffb8, #57e6e6, #b294ff, #57e6e6);
	 background-size: 500% auto;
	 animation: gradient 3s linear infinite;
}
 .webflow-style-input input {
	 flex-grow: 1;
	 color: #7800ff;
	 font-size: 1.8rem;
	 line-height: 2.4rem;
	 vertical-align: top;
}
 .webflow-style-input input::-webkit-input-placeholder {
	 color: #7881a1;
}
 .webflow-style-input button {
	 color: #7881a1;
	 font-size: 2.4rem;
	 line-height: 2.4rem;
	 vertical-align: middle;
	 transition: color 0.25s;
}
 .webflow-style-input button:hover {
	 color: #7800ff;
}
 .response {
	 text-align: center;
	 font-size: 2rem;
	 font-weight: normal;
	 margin-bottom: 1rem;
	 color: #7800ff;
}
 .spinner {
	 border: 16px solid #f3f3f3;
	/* Light grey */
	 border-top: 16px solid #7800ff;
	/* Blue */
	 border-radius: 50%;
	 width: 80px;
	 height: 80px;
	 animation: spin 2s linear infinite;
	 margin: 0 auto;
	/* Center the loader */
}
 @keyframes spin {
	 0% {
		 transform: rotate(0deg);
	}
	 100% {
		 transform: rotate(360deg);
	}
}
/* Modal */
 .modal {
	 display: none;
	/* Hidden by default */
	 position: fixed;
	/* Stay in place */
	 z-index: 999;
	/* Sit on top */
	 left: 0;
	 top: 0;
	 width: 100%;
	/* Full width */
	 height: 100%;
	/* Full height */
	 overflow: auto;
	/* Enable scroll if needed */
	 background-color: rgba(0, 0, 0, 0.4);
	/* Black w/ opacity */
}
 .modal-content {
	 background-color: #fefefe;
	 margin: 15% auto;
	/* 15% from the top and centered */
	 padding: 20px;
	 border: 1px solid #888;
	 width: 50%;
	/* Could be more or less, depending on screen size */
}
 .close {
	 color: #aaa;
	 float: right;
	 font-size: 28px;
	 font-weight: bold;
	 cursor: pointer;
}
 .close:hover, .close:focus {
	 color: black;
	 text-decoration: none;
	 cursor: pointer;
}
 </style>

  <div class="container">
  
  <div class="demo-flex-spacer"></div>

  <div class="webflow-style-input">
    <input type="text" id="queryInput" placeholder="Type your query here... e.g. CRM for retail with 50 employees">
    <button id="fetchInsightsButton">Get Insights</button>
    </div>
    <div class="demo-flex-spacer"></div>
    <div id="loader" class="spinner" style="display: none;"></div>
      <div class="response" id="responseArea"></div>
    </div>
 <!-- The Modal -->
 <script>

  
function fetchInsights() {
    const query = document.getElementById('queryInput').value;
    console.log("fetch");
    // Show the loader
    document.getElementById('loader').style.display = 'block';

    fetch('https://api.jivv.ai/getInsights', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query: query })
    })
    .then(response => response.json())
    .then(data => {
        // Hide the loader
        document.getElementById('loader').style.display = 'none';

        if (data.insight) {
            let formattedText = data.insight.replace(/\\n/g, '<br>'); 
            formattedText += '<br><br>🚀 <strong>Unlock Full Insights!</strong><br>Dive deeper, automate insights, and stay ahead with Jivv. Why wait?';
            formattedText += '<br><br><button onclick="showModal()" style="background-color: #7800FF; color: white; padding: 10px 20px; border: none; border-radius: 5px; cursor: pointer; font-size: 16px;">Join Jivv Now!</button>';
            document.getElementById('responseArea').innerHTML = formattedText;
        } else {
            document.getElementById('responseArea').textContent = "Error: Unable to fetch insights.";
        }
    })
    .catch(error => {
        // Hide the loader in case of an error
        document.getElementById('loader').style.display = 'none';

        console.error('Error:', error);
        document.getElementById('responseArea').textContent = "Error: Unable to fetch insights.";
    });
}

// Get the modal
var modal = document.getElementById('demoModal');

// Get the button that opens the modal
var btn = document.querySelector('button[onclick="showModal()"]');

// Get the <span> element that closes the modal
var span = document.getElementsByClassName("close")[0];

// When the user clicks the button, open the modal 
function showModal() {
    modal.style.display = "block";
    // You can initiate the HubSpot form here if needed
}

// When the user clicks on <span> (x), close the modal
span.onclick = function() {
    modal.style.display = "none";
}

// When the user clicks anywhere outside of the modal, close it
window.onclick = function(event) {
    if (event.target == modal) {
        modal.style.display = "none";
    }
}

const button = document.getElementById("fetchInsightsButton");
console.log(button);  // This should log the button element to the console
button.addEventListener("click", fetchInsights);

</script>


<div id="demoModal" class="modal">
  <div class="modal-content">
    <span class="close">&times;</span>
    <h1 class="demo">Get a Personalized Demo and Secure Your Spot in the Beta Program Today!</h1>
    <script>
  (() => {
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";

    script.addEventListener("load", () => {
      hbspt.forms.create({
    region: "na1",
    portalId: "4244155",
    formId: "b7db01a2-25ea-4e18-8b6e-16579e21e1a2"
      });
    });

    document.head.append(script);
  })();
</script>
    <div id="hubspotForm"></div>
  </div>
</div>
<!-- Event snippet for Submit lead form conversion page -->
<script>
  gtag('event', 'conversion', {'send_to': 'AW-11378759599/JguoCOT8ve0YEK-v6LEq'});
</script>
`}
            ></Script>
          </div>
        </div>
      </section>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  )
}

export default GetADemo
