import React from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Player } from '@lottiefiles/react-lottie-player'
import { Helmet } from 'react-helmet'

import MobileNavigation from '../components/mobile-navigation'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Jivv: Optimize Software Management & Architecture with AI</title>
        <meta
          name="description"
          content="Jivv empowers enterprises with smart software management &amp; tailored architecture-as-a-service, ensuring optimal utilization &amp; growth. Dive in with Jivv!"
        />
        <meta
          property="og:title"
          content="Jivv: Optimize Software Management &amp; Architecture with AI"
        />
        <meta
          property="og:description"
          content="Jivv empowers enterprises with smart software management &amp; tailored architecture-as-a-service, ensuring optimal utilization &amp; growth. Dive in with Jivv!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/2dcb23aa-1fcf-410a-99cd-fca1c2aa55d0/4b7c8b80-edd0-461f-9ee8-b6e569103b6f?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <section className="home-hero">
        <div className="home-menu">
          <MobileNavigation></MobileNavigation>
          <div className="home-desktop-navigation">
            <nav className="home-centered">
              <div className="home-left">
                <a
                  href="https://jivv.ai"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link"
                >
                  <img
                    alt="pastedImage"
                    src="/logos/logo_color_transparent.svg"
                    loading="lazy"
                    className="home-logo"
                  />
                </a>
                <div className="home-links">
                  <a href="#about" className="home-link1 Link">
                    About
                  </a>
                  <a href="#howitworks" className="home-link2 Link">
                    How it works
                  </a>
                  <a href="#benefits" className="home-link3 Link">
                    <span>Why Us</span>
                    <br></br>
                  </a>
                </div>
              </div>
              <div className="home-right">
                <span className="home-sign-in Link">Sign in</span>
                <div className="home-get-started actions">
                  <span className="home-text02">Get started</span>
                </div>
                <div id="open-mobile-menu" className="home-burger-menu">
                  <img
                    alt="pastedImage"
                    src="/pastedimage-yxbd.svg"
                    className="home-mobile-menu-button"
                  />
                </div>
              </div>
            </nav>
          </div>
          <div>
            <div className="home-container2">
              <Script
                html={`<script>
      /*
  Mobile menu - Code Embed
  */

  /* listenForUrlChangesMobileMenu() makes sure that if you changes pages inside your app,
  the mobile menu will still work*/

  const listenForUrlChangesMobileMenu = () => {
      let url = location.href;
      document.body.addEventListener("click", () => {
          requestAnimationFrame(() => {
              if (url !== location.href) {
                  runMobileMenuCodeEmbed();
                  url = location.href;
              }
          });
      },
      true
      );
  };

  const runMobileMenuCodeEmbed = () => {
      // Mobile menu
      const mobileMenu = document.querySelector("#mobile-menu")

      // Buttons
      const closeButton = document.querySelector("#close-mobile-menu")
      const openButton = document.querySelector("#open-mobile-menu")
      const navLinks = document.getElementsByClassName("nav-link")



      // On openButton click, set the mobileMenu position left to -100vw
      openButton && openButton.addEventListener("click", function() {
          mobileMenu.style.transform = "translateX(0%)"
      })

      // On closeButton click, set the mobileMenu position to 0vw
      closeButton && closeButton.addEventListener("click", function() {
          mobileMenu.style.transform = "translateX(100%)"
      })

      // On navlink click, set the mobileMenu position to 0vw
      for (const navLink of navLinks) {
  navLink.addEventListener('click', function handleClick(event) {
    console.log('link clicked', event);
    mobileMenu.style.transform = "translateX(100%)"
  });
}
  }

  runMobileMenuCodeEmbed()
  listenForUrlChangesMobileMenu()
</script>
`}
              ></Script>
            </div>
          </div>
        </div>
        <header className="home-header">
          <h1 className="home-text03">
            <span>Optimize your software stack with AI-driven insights</span>
            <br></br>
          </h1>
          <h2 className="home-text06">
            Track, analyze, and refine your SaaS and on-prem tools for maximum
            ROI
          </h2>
          <Link to="/get-a-demo" className="home-navlink">
            <div className="home-get-started1 actions1">
              <span className="home-text07">See the demo</span>
            </div>
          </Link>
        </header>
        <div className="home-dashboard-preview">
          <div className="home-outline">
            <img
              alt="Pulse. Empower your software strategy"
              src="/jivv%20dshbrd-1000h.jpg"
              loading="lazy"
              className="home-image"
            />
          </div>
        </div>
      </section>
      <section className="home-features">
        <div className="home-intro">
          <h3 className="home-text08">
            <span>
              Your digital co-pilot in architecting success!
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
          </h3>
          <span className="home-text11">
            <span>
              In an era where the software landscape is vast and ever-evolving,
              Jivv stands by your side, offering Architecture-as-a-Service
              tailored for today&apos;s dynamic businesses. Dive deep into
              comprehensive usage insights, streamline your software stack, and
              ensure every tool in your arsenal is leveraged to its fullest
              potential. Consider Jivv as the trusted co-pilot for every CTO and
              CIO, guiding you towards optimal software management, cost
              savings, and operational efficiency.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
          </span>
        </div>
        <div id="about" className="home-title">
          <span className="home-text14">
            Features that empower your business
          </span>
          <span className="home-text15">
            Gain early access to our revolutionary usage analytics and
            actionable insights platform, putting an end to underutilized
            software and embracing a new era of efficiency and cost savings
          </span>
        </div>
        <div className="home-cards">
          <div className="home-container3">
            <div className="card">
              <img
                alt="pastedImage"
                src="/icon-track-200h.png"
                className="home-icon"
              />
              <span className="home-text16">
                Real-time usage tracking and analysis
              </span>
              <span className="home-text17">
                Understand how your team utilizes tools, from login frequencies
                to feature engagement
              </span>
            </div>
            <div className="home-publish card">
              <img
                alt="pastedImage"
                src="/shelfware-icon-200h.png"
                className="home-icon1"
              />
              <span className="home-text18">Cost Optimization</span>
              <span className="home-text19">
                Get AI-driven recommendations on plan adjustments, ensuring you
                pay only for what you need
              </span>
            </div>
          </div>
          <div className="home-container4">
            <div className="card home-analyze">
              <img
                alt="pastedImage"
                src="/icon-data1-200h.png"
                className="home-icon2"
              />
              <span className="home-text20">Benchmarking Insights</span>
              <span className="home-text21">
                See how you stack up. Learn what tools companies similar to
                yours are using
              </span>
            </div>
            <div className="card">
              <img
                alt="pastedImage"
                src="/icon-adoption-200h.png"
                className="home-icon3"
              />
              <span className="home-text22">Architect-as-a-Service</span>
              <span className="home-text23">
                Personalized guidance on restructuring your software stack for
                peak efficiency
              </span>
            </div>
          </div>
        </div>
      </section>
      <section id="benefits" className="home-statistics">
        <div className="home-stats">
          <div className="home-stat">
            <h1 className="home-text24">
              <span>30%</span>
              <br></br>
            </h1>
            <span className="home-text27">
              increase in software utilization and ROI
            </span>
            <span className="home-text28">
              Utilize licenses effectively, maximizing your return on investment
            </span>
          </div>
          <div className="home-stat1">
            <h1 className="home-text29">25%</h1>
            <span className="home-text30">
              improvement in productivity and efficiency
            </span>
            <span className="home-text31">
              Identify bottlenecks and streamline processes for enhanced
              efficiency
            </span>
          </div>
          <div className="home-stat2">
            <h1 className="home-text32">60</h1>
            <span className="home-text33">
              saved hours per month on software license management
            </span>
            <span className="home-text34">
              Simplify tracking and renewal processes with automated insights
            </span>
          </div>
        </div>
      </section>
      <section id="howitworks" className="home-how-it-works">
        <div className="home-centered-container">
          <div className="home-heading">
            <span className="home-text35">How it works</span>
            <span className="home-text36 title">
              Empowering your software strategy
            </span>
          </div>
          <div className="home-category">
            <div className="home-headng">
              <span className="home-text37">
                1 — Enroll in the BETA
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text38">
                Sign up for exclusive access and receive your login credentials
              </span>
              <div className="home-get-started2 template-button actions">
                <span className="home-text39">Get started</span>
              </div>
            </div>
            <div className="home-container5">
              <Player
                src="https://lottie.host/c55bad1c-6e44-4f70-b723-931b70e1078b/ESn5mOUKsm.json"
                loop
                speed="1"
                autoplay
                background="transparent"
                className="home-lottie-node"
              ></Player>
            </div>
          </div>
          <div className="home-row">
            <div className="home-category1">
              <div className="home-headng1">
                <span className="home-text40">2 — Track software usage</span>
                <span className="home-text41">
                  Integrate your SaaS and on-prem platforms easily with our
                  secure connectors
                </span>
              </div>
              <img
                alt="Track Software Usage"
                src="/frame%2086-1200w.png"
                loading="lazy"
                className="home-pasted-image"
              />
            </div>
            <div className="home-category2">
              <div className="home-headng2">
                <span className="home-text42">3 — Analyze data</span>
                <span className="home-text43">
                  Access detailed reports on software utilization, costs, and
                  optimization opportunities
                </span>
              </div>
              <img
                alt="Analyze Data"
                src="/group%203-1200w.png"
                loading="lazy"
                className="home-pasted-image1"
              />
            </div>
          </div>
          <div className="home-row1">
            <div className="home-category3">
              <div className="home-headng3">
                <span className="home-text44">4 — Discover insights</span>
                <span className="home-text45">
                  Optimize software allocation, and identify user engagement
                  patterns
                </span>
              </div>
              <img
                alt="Discover Insights"
                src="/frame%2085-1200w.png"
                loading="lazy"
                className="home-pasted-image2"
              />
            </div>
            <div className="home-category4">
              <div className="home-headng4">
                <span className="home-text46">5 — Take action</span>
                <span className="home-text47">
                  Use AI insights or our architect service to refine and enhance
                  your software ecosystem
                </span>
              </div>
              <img
                alt="Take Action"
                src="/group%204-1200w.png"
                loading="lazy"
                className="home-pasted-image3"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="home-action-bar">
        <div className="home-action">
          <div className="home-heading1">
            <span className="home-text48 title">
              Unleash the potential of your software
            </span>
            <span className="home-text49">
              Claim Your Exclusive BETA Access Today!
            </span>
            <div className="home-get-started3 template-button actions">
              <span className="home-text50">
                <span>Start free</span>
                <br></br>
              </span>
            </div>
          </div>
          <div className="home-images">
            <img
              alt="image"
              src="/frame%2024-1200w.png"
              loading="lazy"
              className="home-image1"
            />
          </div>
        </div>
      </section>
      <Footer></Footer>
      <div>
        <div className="home-container7">
          <Script
            html={`<style>
.cd-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(94, 110, 141, 0.9);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  overflow-y: auto;
  z-index: 10000;
}

.cd-popup.is-visible {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}

.cd-popup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;  /* This will keep the content stacked vertically */
  overflow-x: hidden;
  border: none;
  position: relative;
  width: 82% !important;
  height: 95% !important;
  max-width: 82% !important;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background: #fff;
  border-radius: .25em .25em .4em .4em;
  text-align: center;
  box-shadow: none;
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  -o-transform: translateY(-40px);
  transform: translateY(-40px);
  /* Force Hardware Acceleration in WebKit */
  -webkit-backface-visibility: hidden;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.cd-popup-container p {
  margin: 0;
  padding: 3em 1em;
  padding-top: 1em;
}

.cd-popup-container .cd-popup-close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
}

.cd-close-button {
  color: #545454;
  border-bottom: none;
}

.cd-popup-container .cd-popup-close::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 8px;
}

.cd-popup-container .cd-popup-close::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 8px;
}

.is-visible .cd-popup-container {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

@media only screen and (min-width: 1170px) {
  .cd-popup-container {
    margin: 2em auto;
  }
}
</style>
<section style="align-items: center">
<div class="cd-popup contact" role="alert">
  
    <div class="cd-popup-container" style="">
      <p style="">
        <a href="" class="cd-popup-close cd-close-button">
          <i class="fa fa-times" style="pointer-events:none;"></i>
        </a>
      </p>
      <h1 style = "color: #693acf">Get a Personalized Demo and Secure Your Spot in the Beta Program Today!</h1>
      <br>
      <!-- Start of Meetings Embed Script -->


<script>
  (() => {
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";

    script.addEventListener("load", () => {
      hbspt.forms.create({
    region: "na1",
    portalId: "4244155",
    formId: "b7db01a2-25ea-4e18-8b6e-16579e21e1a2"
      });
    });

    document.head.append(script);
  })();
</script>
</div>
  </div>
  <!-- End of Meetings Embed Script -->
      

</section>
<script>
    // Function to close the form
    function closeForm() {
      document.querySelector(".cd-popup").classList.remove("is-visible");
    }

    // Get all elements with the "actions" class and attach click event listeners
    var actionsElements = document.querySelectorAll(".actions");
    actionsElements.forEach(function (element) {
      element.addEventListener("click", function (event) {
        event.preventDefault();
        document.querySelector(".contact").classList.add("is-visible");
        
      });
    });

    // Close popup when clicking x or off popup
    document.querySelector(".cd-popup").addEventListener("click", function (event) {
      if (event.target.classList.contains("cd-popup-close") || event.target.classList.contains("cd-popup")) {
        event.preventDefault();
        closeForm();
      }
    });

    // Close popup when clicking the esc keyboard button
    document.addEventListener("keyup", function (event) {
      if (event.which == 27) {
        closeForm();
      }
    });

    
  </script>





`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

export default Home
