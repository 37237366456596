import React from 'react'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <footer className={`footer-footer ${props.rootClassName} `}>
      <div className="footer-content">
        <main className="footer-main-content">
          <div className="footer-content1">
            <header className="footer-main">
              <a
                href="https://jivv.ai"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link"
              >
                <div className="footer-header">
                  <img
                    alt={props.Branding_alt}
                    src={props.Branding_src}
                    loading="lazy"
                    className="footer-branding"
                  />
                </div>
              </a>
            </header>
          </div>
          <section className="footer-copyright">
            <span className="footer-text">{props.text1}</span>
          </section>
        </main>
        <main className="footer-subscribe"></main>
        <section className="footer-copyright1">
          <span className="footer-text1">{props.text}</span>
        </section>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  Branding_src: '/logos/logo_white_transparent.svg',
  text1: 'Copyright © 2023 JIVV - All Rights Reserved.',
  Branding_alt: 'image',
  rootClassName: '',
  text: 'Copyright © 2023 JIVV - All Rights Reserved.',
}

Footer.propTypes = {
  Branding_src: PropTypes.string,
  text1: PropTypes.string,
  Branding_alt: PropTypes.string,
  rootClassName: PropTypes.string,
  text: PropTypes.string,
}

export default Footer
